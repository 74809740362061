import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import ExternalAds from '@hmn/rtl-web-core/components/Ads/External/ExternalAds.component'
import RevealerNativeAd from '@hmn/rtl-web-core/components/Ads/External/Revealer/Revealer.component'
// import { MinimalPlaceholder } from '@hmn/rtl-web-core/components/Ads/MinimalPlaceholder.component'
import { CategoryColorProvider } from '@hmn/rtl-web-core/context/category-color'
import { useNavigationOpenState } from '@hmn/rtl-web-core/context/navigation'
import createIsolatedHook from '@hmn/rtl-web-core/helpers/createIsolatedHook'
import { useActiveCategory, useNavigation } from '@hmn/rtl-web-core/hooks'
import useUpdatedStateValue from '@hmn/rtl-web-core/hooks/functional/useUpdatedStateValue'
import useBaseLayout, { useBaseLayoutScroll } from '@hmn/rtl-web-core/hooks/useBaseLayout'

import Footer from '@hmn/rtl-net-ui/components/Footer/Footer.component'
import Header from '@hmn/rtl-net-ui/components/Header/Header.component'
import Navigation from '@hmn/rtl-net-ui/components/Navigation/Navigation.component'
import SearchPanel from '@hmn/rtl-net-ui/components/Search/Panel/Panel.component'
import { NetLuna as Luna } from '@hmn/rtl-net-ui/controllers/NetLuna'

import style from './BaseLayout.style'
import Content from './Content.component'

const LayoutStyled = styled.main(props => ({ ...style(props) }))

const useBaseLayoutHooks = () => {
    useBaseLayout()
    useBaseLayoutScroll()
}
const IsolatedUseBaseLayoutHooks = createIsolatedHook(useBaseLayoutHooks)
const wallpaperProps = {
    // Placeholder: MinimalPlaceholder,
    // minHeight: 0
}
function BaseLayout({ children, navigationData: navigationDataIn, categoryColor = undefined }) {
    const [isNavigationOpen] = useNavigationOpenState()
    const [activeCategory, activeSubcategory] = useActiveCategory()
    const navigationData = useUpdatedStateValue(navigationDataIn, newData => typeof newData === 'object')

    const navList = useNavigation(navigationData, 'header')
    const hasSubnavList = navList.find(item => item.href === activeCategory)?.items?.length
    const categoryName = activeCategory.replace('/', '')

    return (
        <LayoutStyled
            isNavigationOpen={isNavigationOpen}
            className={`main_layout ${hasSubnavList && 'has_subnavigation'} is_section_${categoryName}`}>
            <IsolatedUseBaseLayoutHooks />
            <CategoryColorProvider categoryColor={categoryColor}>
                <Header
                    navList={navList}
                    activeCategory={activeCategory}
                    activeSubcategory={activeSubcategory}
                    categoryName={categoryName}
                />
                <Navigation navigationData={navigationData} />
                <SearchPanel />
                <ExternalAds wallpaperProps={wallpaperProps} />
                <RevealerNativeAd>
                    <Content>{children}</Content>
                </RevealerNativeAd>
                <Luna theme="netDark">
                    <Footer navigationData={navigationData} />
                </Luna>
            </CategoryColorProvider>
        </LayoutStyled>
    )
}

BaseLayout.propTypes = {
    navigationData: PropTypes.shape({}),
    categoryColor: PropTypes.string
}

BaseLayout.defaultProps = {
    navigationData: undefined,
    categoryColor: undefined
}

export default BaseLayout
